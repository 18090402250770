/* Splash page */

body{
    text-align: center;
    height: 100vh;
    background-color: #A9C9FF;
    background-image: linear-gradient(180deg, #A9C9FF 0%, #FFBBEC 100%);
    font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif; 
  box-sizing: border-box;
  margin: 0;
  }
  /* .splash-container{
    width: 80%;
    margin:0 auto;
} */
.image-container{
  height:100vh;
  position:relative
}


.splash-image{
  position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    height: 45vw;
    width: 90vw;
    object-fit: contain;
    object-position: 0 60px;
    margin: 0 auto;
}

main{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.splash-title{
    font-size: 84px;
    position: relative;
    color: white;
    text-shadow: 1px 1px 3px black;
    top: 75px;
  }
  
  
  .signin-form{
    position: relative;
    width: 500px;
    height: 230px;
    top: 15vh;
    box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
    border-radius: 8px;
    background-color: rgba(244, 255, 255, .5);
    margin: auto;
  }
  
  .signin-form input{
    position: relative;
    top: 25%;
    height:40px;
    justify-items: center;
    border-radius: 8px;
    border: #A9C9FF;
    margin-inline: 5px;
    padding-left: 10px;
    font-size: 1.1rem;
  }
  
  .btn-container {
    display: flex;
    padding-top: 20px;
    justify-content: center;
    position: relative;
    top: 25%;
  }
  
button{
    align-items: center;
    background-color: #6798ec;
    border: 0;
    width: 180px;
    border-radius: 100px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    font-size: 24px;
    font-weight: 400;
    justify-content: center;
    line-height: 20px;
    max-width: 480px;
    min-height: 50px;
    min-width: 0px;
    overflow: hidden;
    padding: 0px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    touch-action: manipulation;
    transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
  }
  
button:hover,
button:focus { 
    background-color: #4685f3;
    color: #ffffff;
  }
  
button:active {
    background: #09223b;
    color: rgb(255, 255, 255, .7);
  }

.error-msg{
    position:relative;
    top: 30%;
    color: red;
}

.hidden{
    display: none;
}

/* Record table */

.main-title{
  font-size: 72px;
  position: relative;
  color: white;
  text-shadow: 1px 1px 3px black;
}

.container{
    width: clamp(500px, 45%, 55%) ;
    margin:0 auto;
    box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
    border-radius: 8px;
    background-color: rgba(244, 255, 255, .8);
    padding-block: 20px;
    padding-inline: 12px;
}

p{
  font-size: 1.1rem;
}

label{
  font-size: 1.1rem;
  font-weight: 400;
}

span{
  font-size: 1.1rem;
  font-weight: 400;
}

.table-container{
    max-height: 400px;
    width: 500px;
    overflow-y: auto;
    margin: 0px auto;
    margin-top: 25px;
}
#recordTable {
    border-collapse: collapse;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 483px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

#recordTable thead tr {
    background-color: #2196F3;
    color: #ffffff;
    text-align: center;
}

#recordTable th,
#recordTable td {
    padding: 12px 15px;
}

#recordTable tbody tr {
    border-bottom: 1px solid #dddddd;
}

#recordTable tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

#recordTable tbody tr:nth-of-type(odd) {
    background-color: #ffffff;
}

#recordTable tbody tr:last-of-type {
    border-bottom: 2px solid #2196F3;
}
#submissionLink{
  margin-top: 24px;
}
/* Form fields*/

#form{
    padding-right: 100px;
    width: 75%;
    margin:0 auto;
}

.submissionText{
  padding-inline: 16px;
}

.field{
    margin:0 auto;
    width: 310px;
    gap: 10px;
    padding-block: 5px;
    display: flex;
    justify-content: space-between;
}
.field label, .field span{
  font-weight: 500;
}
.toggle{
    justify-content: flex-start;
    gap: 33px;
}

input{
    width: 200px;
}

textarea{
    resize: none;
    margin-left: 27px;
    width: 300px;
    height: 100px;
}
.switch {
    position: relative;
    display: flex;
    width: 60px;
    height: 34px;
  }
  
  .switch input {display:none;}
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #6798ec;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #6798ec;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  #submissionBtn{
    padding-left: 105px;
  }

  /* Thank you */

  #thank-you{
    padding-top: 32px;
    font-size: 1.5rem;
  }

  @media (max-width:1100px){
    .splash-image{
      display:none;
    }
  
    .signin-form{
      top: 5vh;
    }
  }